import React from "react";

const About = () => {
  return (
    <div className="px-4 py-4 lg:container lg:mx-auto lg:px-4">
      <div className="flex flex-col justify-center items-center h-full space-y-4">
        <h1 className="text-3xl font-bold text-center max-w-3xl">
          NOBARYUKBRO - Your Ultimate Destination for Sports Streaming
        </h1>
        <p className="text-lg text-justify leading-relaxed max-w-3xl">
          Welcome to nobaryukbro.my.id, where the thrill of live sports meets
          the convenience of streaming at your fingertips. Dive into the world
          of sports with our extensive database featuring a wide range of
          sporting events from around the globe. Whether you're a fan of
          football, basketball, tennis, or niche sports, we have something for
          every sports enthusiast.
        </p>
        <p className="text-lg text-justify leading-relaxed max-w-3xl">
          At nobaryukbro.my.id, we pride ourselves on our comprehensive
          collection that spans various sports, leagues, and tournaments. From
          major league championships to local tournaments, discover and stream
          your favorite sports events effortlessly. Our database is regularly
          updated, ensuring you never miss out on the latest matches and
          competitions.
        </p>
        <p className="text-lg text-justify leading-relaxed max-w-3xl">
          Experience the future of sports streaming with our personalized
          recommendation system. Our advanced algorithms analyze your viewing
          preferences and history to curate a tailored list of recommended
          matches and events. Explore new sports and games that match your
          interests, making every streaming session exciting and engaging.
        </p>
        <p className="text-lg text-justify leading-relaxed max-w-3xl">
          Enjoy high-definition streaming quality that brings the stadium
          atmosphere right into your living room. Watch sports in crystal-clear
          resolution with immersive audio, ensuring you catch every moment of
          the action as if you were there in person. Our adaptive streaming
          technology adjusts to your internet speed, delivering seamless
          playback without interruptions.
        </p>
        <p className="text-lg text-justify leading-relaxed max-w-3xl">
          nobaryukbro.my.id is designed for ultimate convenience and
          accessibility. Whether you're using a laptop, tablet, or smartphone,
          enjoy watching sports anytime, anywhere. Stay connected to your
          favorite teams and athletes, whether you're at home or on the go, with
          our user-friendly platform.
        </p>
        <p className="text-lg text-justify leading-relaxed max-w-3xl">
          Join our vibrant community of sports enthusiasts. Engage in
          discussions, share match predictions, and connect with fellow fans
          through our dedicated forums and social features. Celebrate victories,
          analyze plays, and stay updated on the latest sports news together
          with like-minded individuals who share your passion for sports.
        </p>
        <p className="text-lg text-justify leading-relaxed max-w-3xl">
          In summary, nobaryukbro.my.id is your go-to destination for sports
          streaming, offering a diverse database, personalized recommendations,
          high-definition streaming, device compatibility, and an active
          community of sports fans. Get ready to immerse yourself in the world
          of sports like never before. Welcome to nobaryukbro.my.id, where every
          match is an experience worth cheering for.
        </p>
      </div>
    </div>
  );
};

export default About;
