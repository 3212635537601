// HelmetComponent.js
import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

const HelmetComponent = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>NOBARYUKBRO</title>
        <meta name="description" content="FREE WEBSITE STREAMING SPORTS" />
        <meta name="theme-color" content="#000000" />
        <link rel="apple-touch-icon" href="./favicon-96x96.png" />
        <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
      </Helmet>
    </HelmetProvider>
  );
};

export default HelmetComponent;
