import React from "react";

const Replay = () => {
  return (
    <div className="px-4 py-4 lg:container lg:mx-auto lg:px-4">
      <div className="flex justify-center items-center h-full">
        <h1 className="text-3xl font-bold">Coming Soon</h1>
      </div>
    </div>
  );
};

export default Replay;
