import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Card from "../components/Card";
import Card2 from "../components/Card2";
import { Helmet } from "react-helmet";

const Home = () => {
  const [activeTab, setActiveTab] = useState("Football");
  const [matches, setMatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const apiUrls = useMemo(
    () => ({
      Football: "https://nyb.nobaryukbro.my.id/api/football",
      Badminton: "https://nyb.nobaryukbro.my.id/api/badminton",
      Basketball: "https://nyb.nobaryukbro.my.id/api/basketball",
      Motorsports: "https://nyb.nobaryukbro.my.id/api/motosports",
      UFC: "https://nyb.nobaryukbro.my.id/api/ufc",
      Other: "https://nyb.nobaryukbro.my.id/api/other",
    }),
    []
  );

  const fetchMatches = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(apiUrls[activeTab]);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();

      const now = moment();

      // Filter out matches that have already finished for Football only
      const filteredData =
        activeTab === "Football"
          ? data.filter((match) =>
              now.isBefore(
                moment(`${match.date} ${match.time}`, "YYYY-MM-DD HH:mm")
                  .clone()
                  .add(160, "minutes")
              )
            )
          : data;

      // Sort matches by date and time
      const sortedMatches = filteredData.sort((a, b) => {
        const dateTimeA = moment(`${a.date} ${a.time}`, "YYYY-MM-DD HH:mm");
        const dateTimeB = moment(`${b.date} ${b.time}`, "YYYY-MM-DD HH:mm");

        // Check if match is live
        const isLiveA = now.isBetween(
          dateTimeA,
          dateTimeA.clone().add(2, "hours")
        );
        const isLiveB = now.isBetween(
          dateTimeB,
          dateTimeB.clone().add(2, "hours")
        );

        if (isLiveA && !isLiveB) return -1;
        if (!isLiveA && isLiveB) return 1;

        return dateTimeA - dateTimeB;
      });

      setMatches(sortedMatches);
    } catch (error) {
      console.error("Error fetching matches:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [activeTab, apiUrls]);

  useEffect(() => {
    let isMounted = true;
    const loadData = async () => {
      await fetchMatches();
    };

    if (isMounted) {
      loadData();
    }

    return () => {
      isMounted = false;
    };
  }, [fetchMatches]);

  const handleClick = (match) => {
    const slug = `${match.homeName}-vs-${match.awayName}`
      .toLowerCase()
      .replace(/ /g, "-");
    navigate(`/live/${slug}`, { state: match });
  };

  const renderContent = () => {
    if (loading) {
      return <div>Loading...</div>;
    }
    if (error) {
      return <div>Error: {error}</div>;
    }
    if (matches.length === 0) {
      return <div>No matches found</div>;
    }
    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {matches.map((match) => {
          if (["Motorsports", "UFC", "Other"].includes(activeTab)) {
            return (
              <Card2
                key={match.id}
                competition={match.competition}
                logo={match.logo}
                urlStreams={match.urlStreams}
                onClick={() => handleClick(match)}
              />
            );
          } else {
            return (
              <Card
                key={match.id}
                competition={match.competition}
                date={match.date}
                time={match.time}
                homeName={match.homeName}
                homeLogo={match.homeLogo}
                awayName={match.awayName}
                awayLogo={match.awayLogo}
                onClick={() => handleClick(match)}
                tab={activeTab} // Tambahkan prop tab untuk menentukan tab aktif
              />
            );
          }
        })}
      </div>
    );
  };

  return (
    <div className="px-4 py-4 lg:container lg:mx-auto lg:px-4">
      <Helmet>
        <title>NOBARYUKBRO</title>
        <meta name="description" content="Home page of NOBARYUKBRO" />
        <meta
          name="keywords"
          content="football, badminton, basketball, motorsports, UFC, sports streaming"
        />
        <link rel="canonical" href="https://nyb.nobaryukbro.my.id" />
        <meta property="og:title" content="NOBARYUKBRO" />
        <meta property="og:description" content="Home page of NOBARYUKBRO" />
        <meta property="og:url" content="https://nyb.nobaryukbro.my.id" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="../assets/mstile-310x310.png" />
        <meta name="twitter:card" content="streaming sports gratis" />
        <meta name="twitter:title" content="NOBARYUKBRO" />
        <meta name="twitter:description" content="Home page of NOBARYUKBRO" />
        <meta name="twitter:image" content="../assets/mstile-310x310.png" />
      </Helmet>
      <div className="flex gap-2 mb-4 overflow-x-auto whitespace-nowrap">
        {[
          "Football",
          "Badminton",
          "Basketball",
          "Motorsports",
          "UFC",
          "Other",
        ].map((category) => (
          <button
            key={category}
            className={`px-4 py-2 rounded ${
              activeTab === category ? "bg-[#1f2937] text-white" : "bg-gray-200"
            }`}
            onClick={() => setActiveTab(category)}
          >
            {category}
          </button>
        ))}
      </div>
      <div>{renderContent()}</div>
    </div>
  );
};

export default Home;
