import React, { useState, useEffect } from "react";
import backgroundImage from "../assets/dl.beatsnoop.com-final-sLKF3c1daS.jpg"; // Perbarui path impor gambar
import { useNavigate } from "react-router-dom";

const Card2 = ({ competition, logo, urlStreams }) => {
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (showPopup) {
      document.documentElement.classList.add("overflow-hidden");
    } else {
      document.documentElement.classList.remove("overflow-hidden");
    }

    return () => {
      document.documentElement.classList.remove("overflow-hidden");
    };
  }, [showPopup]);

  const handleClick = () => {
    const newSlug = competition.toLowerCase().replace(/ /g, "-");
    navigate(`/live/${newSlug}`, { state: { competition, urlStreams } });
  };

  return (
    <div>
      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded-lg text-center max-w-xs w-full mx-4">
            <p className="text-lg font-semibold mb-4 text-gray-800">
              You can go to the match page 10 minutes before the match.
            </p>
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors duration-300"
              onClick={() => setShowPopup(false)}
            >
              OK
            </button>
          </div>
        </div>
      )}
      <div
        onClick={handleClick}
        className={`relative rounded-lg p-4 flex flex-col items-center transform transition-transform md:hover:scale-105 cursor-pointer`}
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundBlendMode: "overlay",
          backgroundColor: "rgba(31, 41, 55, 0.5)", // Perbarui warna background untuk membuat logo lebih terlihat
        }}
      >
        <div className="bg-white p-4 rounded-full">
          <img
            src={logo}
            alt={`${competition} Logo`}
            className="w-32 h-32 md:w-40 md:h-40 object-contain" // Menyesuaikan ukuran logo
          />
        </div>
      </div>
    </div>
  );
};

export default Card2;
