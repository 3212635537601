import { useEffect } from "react";
import { loadScripts } from "../utils/loadScripts";

const LoadScripts = () => {
  useEffect(() => {
    const cleanup = loadScripts();
    return cleanup;
  }, []);

  return null;
};

export default LoadScripts;
