export const loadScripts = () => {
  // Script untuk kontrol shortcut dan klik kanan
  const script2Content = `
    // Nonaktifkan klik kanan
    document.addEventListener('contextmenu', (e) => e.preventDefault());

    function ctrlShiftKey(e, keyCode) {
      return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
    }

    document.onkeydown = (e) => {
      // Nonaktifkan F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
      if (
        event.keyCode === 123 ||
        ctrlShiftKey(e, 'I') ||
        ctrlShiftKey(e, 'J') ||
        ctrlShiftKey(e, 'C') ||
        (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0))
      )
        return false;
    };
  `;

  const script2 = document.createElement("script");
  script2.type = "text/javascript";
  script2.async = true;
  script2.text = script2Content;
  document.body.appendChild(script2);
};
