import React from "react";

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white p-4">
      <p className="text-sm text-center">
        Disclaimer: Our platform solely provides links to audiovisual content
        hosted on third-party servers and supplied and/or transmitted by third
        parties. We neither host nor transmit any audiovisual content ourselves,
        nor do we control or influence such content. Consequently, we cannot
        assume any liability for content transmitted by others. Responsibility
        for this content rests with the respective hosts or transmitters. We do
        not hold any affiliations with, nor do we claim to be affiliated with,
        any of the owners of the streams and/or videos. All content remains the
        intellectual property of its respective owners.
      </p>
      <p className="text-center mt-2">&copy; 2024 by NOBARYUKBRO.</p>
    </footer>
  );
};

export default Footer;
