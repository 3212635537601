import React, { useState, useEffect } from "react";
import moment from "moment";
import backgroundImage from "../assets/dl.beatsnoop.com-final-sLKF3c1daS.jpg"; // Perbarui path impor gambar

const Card = ({
  competition,
  date,
  time,
  homeName,
  homeLogo,
  awayName,
  awayLogo,
  onClick,
  tab,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const [countdown, setCountdown] = useState("");
  const [isLive, setIsLive] = useState(false);
  const [isClickable, setIsClickable] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      const eventTime = moment(`${date}T${time}`, "YYYY-MM-DDTHH:mm");
      const now = moment();
      const diff = eventTime.diff(now);

      if (tab === "Football") {
        if (diff <= 0 && diff >= -120 * 60 * 1000) {
          setIsLive(true);
          setCountdown("The match is in progress");
        } else if (diff < -120 * 60 * 1000) {
          setIsLive(false);
          setCountdown("The match is over");
        } else if (diff > 24 * 60 * 60 * 1000) {
          setIsLive(false);
          const days = Math.ceil(diff / (24 * 60 * 60 * 1000));
          setCountdown(`in ${days} day${days > 1 ? "s" : ""}`);
        } else {
          setIsLive(false);
          const duration = moment.duration(diff);
          const hours = duration.hours();
          const minutes = duration.minutes();
          const seconds = duration.seconds();
          setCountdown(`Start in ${hours}h ${minutes}m ${seconds}s`);
        }

        if (diff < -160 * 60 * 1000) {
          setIsClickable(false);
        } else {
          setIsClickable(true);
        }
      } else if (["Badminton", "Basketball"].includes(tab)) {
        if (diff > 24 * 60 * 60 * 1000) {
          const days = Math.ceil(diff / (24 * 60 * 60 * 1000));
          setCountdown(`in ${days} day${days > 1 ? "s" : ""}`);
        } else if (diff <= 0) {
          setCountdown("Happy watching");
        } else {
          const duration = moment.duration(diff);
          const hours = duration.hours();
          const minutes = duration.minutes();
          const seconds = duration.seconds();
          setCountdown(`Start in ${hours}h ${minutes}m ${seconds}s`);
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [date, time, tab]);

  useEffect(() => {
    if (showPopup) {
      document.documentElement.classList.add("overflow-hidden");
    } else {
      document.documentElement.classList.remove("overflow-hidden");
    }

    return () => {
      document.documentElement.classList.remove("overflow-hidden");
    };
  }, [showPopup]);

  const canNavigateToLive = () => {
    const eventTime = moment(`${date}T${time}`, "YYYY-MM-DDTHH:mm");
    const now = moment();

    return eventTime.isSameOrBefore(now.add(10, "minutes"));
  };

  const handleClick = () => {
    if (isClickable) {
      if (canNavigateToLive()) {
        onClick();
      } else {
        setShowPopup(true);
      }
    }
  };

  const eventTime = moment(`${date}T${time}`, "YYYY-MM-DDTHH:mm");
  const now = moment();
  const timeDiff = eventTime.diff(now);

  if (tab === "Football" && timeDiff < -160 * 60 * 1000) {
    return null;
  }

  return (
    <div>
      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded-lg text-center max-w-xs w-full mx-4">
            <p className="text-lg font-semibold mb-4 text-gray-800">
              You can go to the match page 10 minutes before the match.
            </p>
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors duration-300"
              onClick={() => setShowPopup(false)}
            >
              OK
            </button>
          </div>
        </div>
      )}
      <div
        onClick={handleClick}
        className={`relative rounded-lg p-4 flex flex-col items-center transform transition-transform md:hover:scale-105 cursor-pointer bg-gray-800 ${
          !isClickable ? "cursor-not-allowed" : ""
        }`}
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundBlendMode: "overlay",
          backgroundColor: "rgba(31, 41, 55, 0.8)",
        }}
      >
        {isLive && (
          <div className="absolute top-4 right-6 flex items-center">
            <span className="relative flex h-3 w-3 mr-2">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
              <span className="relative inline-flex rounded-full h-3 w-3 bg-green-500"></span>
            </span>
            <span className="text-green-500">Live</span>
          </div>
        )}
        <div className="flex flex-col items-center mb-2">
          <div className="flex items-center mb-2">
            <span className="font-extrabold text-white text-2xl">
              {competition}
            </span>
          </div>
          <div className="text-center text-white mb-2">{countdown}</div>
        </div>
        <div className="flex justify-between items-center w-full mb-2">
          <div className="flex flex-col items-center w-1/3">
            <img
              src={homeLogo}
              alt={`${homeName} Logo`}
              className="w-14 h-14 mb-2 object-contain"
            />
          </div>
          <div className="flex flex-col items-center justify-center w-1/3">
            <div className="text-center text-white text-xl font-bold">VS</div>
          </div>
          <div className="flex flex-col items-center w-1/3">
            <img
              src={awayLogo}
              alt={`${awayName} Logo`}
              className="w-14 h-14 mb-2 object-contain"
            />
          </div>
        </div>
        <div className="flex justify-between w-full">
          <div className="flex flex-col items-center w-1/3">
            <h2 className="text-lg font-semibold text-white text-center">
              {homeName}
            </h2>
          </div>
          <div className="flex flex-col items-center justify-center w-1/3"></div>
          <div className="flex flex-col items-center w-1/3">
            <h2 className="text-lg font-semibold text-white text-center">
              {awayName}
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
