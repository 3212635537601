import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./page/Home";
import Replay from "./page/Replay";
import About from "./page/About";
import Live from "./page/Live";
import HelmetComponent from "./components/HelmetComponent";

const App = () => {
  return (
    <Router>
      <HelmetComponent />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="replay" element={<Replay />} />
          <Route path="about" element={<About />} />
          <Route path="live/:slug" element={<Live />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
