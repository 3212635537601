import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import times from "../assets/times.svg";
import hamburger_menu from "../assets/hamburger-menu.svg";

const Navbar = () => {
  const [isOpen, setOpen] = useState(false);
  const menuRef = useRef(null);
  const buttonRef = useRef(null);

  const toggleMenu = () => setOpen((prevState) => !prevState);

  const handleClickOutside = (event) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setOpen(false);
    }
  };

  const handleResize = () => {
    if (window.innerWidth >= 1024) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      document.body.style.overflow = "hidden"; // Prevent scrolling
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
      document.body.style.overflow = "auto"; // Allow scrolling
    }

    window.addEventListener("resize", handleResize);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("resize", handleResize);
      document.body.style.overflow = "auto"; // Allow scrolling
    };
  }, [isOpen]);

  return (
    <nav className="font-extrabold text-[2rem] sticky z-50 top-0 bg-white shadow-md">
      <div className="px-4 py-3 flex items-center justify-between lg:container lg:mx-auto">
        <Link className="cursor-pointer" to="/">
          <span>NOBARYUKBRO.</span>
        </Link>
        <button
          ref={buttonRef}
          onClick={toggleMenu}
          aria-expanded={isOpen}
          aria-controls="mobile-menu"
          className="lg:hidden"
        >
          <img
            className="cursor-pointer w-10 h-10"
            src={isOpen ? times : hamburger_menu}
            alt="menu"
          />
        </button>
        <div className="hidden lg:block">
          <ul className="flex gap-6">
            <li>
              <Link
                className="cursor-pointer hover:bg-[#1f2937] hover:text-white rounded-lg px-3 py-2 transition-colors duration-200 ease-in-out"
                to="/replay"
              >
                Replay
              </Link>
            </li>
            <li>
              <Link
                className="cursor-pointer hover:bg-[#1f2937] hover:text-white rounded-lg px-3 py-2 transition-colors duration-200 ease-in-out"
                to="/about"
              >
                About
              </Link>
            </li>
            <li>
              <a
                className="cursor-pointer hover:bg-[#1f2937] hover:text-white rounded-lg px-3 py-2 transition-colors duration-200 ease-in-out"
                href="https://t.me/nobaryukbrooo"
                target="_blank"
                rel="noopener noreferrer"
              >
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40 transition-opacity duration-300"
          onClick={toggleMenu}
        ></div>
      )}
      <div
        ref={menuRef}
        className={`lg:hidden fixed top-0 right-0 h-full w-[200px] bg-[#1f2937] p-5 rounded-l-lg shadow-xl z-50 transition-transform duration-300 ease-in-out transform ${
          isOpen ? "translate-x-0" : "translate-x-full"
        }`}
        id="mobile-menu"
      >
        <ul className="text-white font-bold my-3 item-center space-y-2">
          <li className="hover:bg-[#374151] rounded-lg transition-colors duration-200 ease-in-out">
            <Link
              className="cursor-pointer block pl-3 py-2"
              to="/replay"
              onClick={toggleMenu}
            >
              Replay
            </Link>
          </li>
          <li className="hover:bg-[#374151] rounded-lg transition-colors duration-200 ease-in-out">
            <Link
              className="cursor-pointer block pl-3 py-2"
              to="/about"
              onClick={toggleMenu}
            >
              About
            </Link>
          </li>
          <li className="hover:bg-[#374151] rounded-lg transition-colors duration-200 ease-in-out">
            <a
              className="cursor-pointer block pl-3 py-2"
              href="https://t.me/nobaryukbrooo"
              target="_blank"
              rel="noopener noreferrer"
              onClick={toggleMenu}
            >
              Contact
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
