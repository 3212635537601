import React, { useEffect, useState, useMemo } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { FaTelegramPlane } from "react-icons/fa";
import moment from "moment";
import Card from "../components/Card";

const Live = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [iframeSrc, setIframeSrc] = useState("");
  const [loading, setLoading] = useState(true);
  const [activeSource, setActiveSource] = useState(null);
  const [ongoingMatches, setOngoingMatches] = useState([]);

  // Check if match data is in location.state or in localStorage
  const matchFromState = location.state;
  const matchFromStorage = JSON.parse(localStorage.getItem("matchData"));

  const match = useMemo(
    () => matchFromState || matchFromStorage || {},
    [matchFromState, matchFromStorage]
  );

  useEffect(() => {
    // Scroll to top when the component is mounted
    window.scrollTo(0, 0);

    // Save match data to localStorage if it exists in location.state
    if (matchFromState) {
      localStorage.setItem("matchData", JSON.stringify(matchFromState));
    }

    if (match.urlStreams && match.urlStreams.length > 0) {
      setIframeSrc(match.urlStreams[0]);
      setActiveSource(match.urlStreams[0]);
    }

    const fetchOngoingMatches = async () => {
      try {
        const [footballRes, badmintonRes, basketballRes] = await Promise.all([
          fetch("https://nyb.nobaryukbro.my.id/api/football"),
          fetch("https://nyb.nobaryukbro.my.id/api/badminton"),
          fetch("https://nyb.nobaryukbro.my.id/api/basketball"),
        ]);

        if (!footballRes.ok || !badmintonRes.ok || !basketballRes.ok) {
          throw new Error("Network response was not ok");
        }

        const footballData = await footballRes.json();
        const badmintonData = await badmintonRes.json();
        const basketballData = await basketballRes.json();

        const allMatches = [
          ...footballData,
          ...badmintonData,
          ...basketballData,
        ];
        const now = moment();
        const filteredMatches = allMatches.filter((m) => {
          const eventTime = moment(`${m.date} ${m.time}`, "YYYY-MM-DD HH:mm");
          const diff = eventTime.diff(now);
          return (
            diff <= 3600000 && diff >= -2 * 60 * 60 * 1000 && match.id !== m.id
          );
        });

        setOngoingMatches(filteredMatches);
      } catch (error) {
        console.error("Error fetching ongoing matches:", error);
      }
    };

    fetchOngoingMatches();
  }, [matchFromState, match.urlStreams, match.id]);

  useEffect(() => {
    const handleFullscreenChange = () => {
      if (document.fullscreenElement) {
        if (window.screen.orientation && window.screen.orientation.lock) {
          window.screen.orientation.lock("landscape").catch(() => {});
        }
      } else {
        if (window.screen.orientation && window.screen.orientation.unlock) {
          window.screen.orientation.unlock();
        }
      }
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  const handleIframeLoad = () => {
    setLoading(false);
  };

  const handleSourceClick = (source) => {
    setLoading(true);
    setIframeSrc(source);
    setActiveSource(source);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleCardClick = (matchData) => {
    const slug = matchData.homeName
      ? `${matchData.homeName
          .replace(/\s+/g, "-")
          .toLowerCase()}-vs-${matchData.awayName
          .replace(/\s+/g, "-")
          .toLowerCase()}`
      : matchData.competition.replace(/\s+/g, "-").toLowerCase();

    localStorage.setItem("matchData", JSON.stringify(matchData));
    navigate(`/live/${slug}`, { state: matchData });
  };

  if (!match.date && !match.competition) {
    return (
      <div className="container mx-auto p-4 text-center">
        <p className="text-red-500">Error: No match data available.</p>
        <Link to="/" className="text-blue-500 hover:underline">
          Back to Home
        </Link>
      </div>
    );
  }

  return (
    <div className="flex flex-col min-h-screen">
      <main className="flex-grow px-4 py-4 lg:container lg:mx-auto lg:px-4">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
          <div className="lg:col-span-2">
            <div className="relative aspect-video w-full rounded-lg mb-4 bg-gray-200">
              {loading && (
                <div className="absolute inset-0 flex flex-col items-center justify-center">
                  <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-b-4 border-gray-900 mb-4"></div>
                  <div className="text-gray-500 text-2xl">
                    Loading Stream...
                  </div>
                </div>
              )}
              <iframe
                src={iframeSrc}
                title="Live Stream"
                className={`absolute aspect-video inset-0 w-full h-full ${
                  loading ? "hidden" : "block"
                }`}
                onLoad={handleIframeLoad}
                allow="encrypted-media"
                allowFullScreen
              ></iframe>
            </div>

            <div className="bg-gray-800 text-white p-4 rounded-lg mb-4">
              {match.homeName && match.awayName ? (
                <>
                  <h2 className="text-xl font-bold mb-1">
                    {match.homeName} VS {match.awayName}
                  </h2>
                  <p className="text-md">{match.competition}</p>
                </>
              ) : (
                <h2 className="text-xl font-bold mb-1">{match.competition}</h2>
              )}
            </div>

            <div className="bg-gray-100 p-4 rounded-lg mb-4">
              <h3 className="text-lg font-semibold mb-2">
                Select More Source:
              </h3>
              <div className="flex flex-wrap gap-4">
                <a
                  href="https://isabellaentire.com/zjxw5d0c?key=937c0545bfc6a8e5700000821b186ab6"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`px-4 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 ${
                    activeSource ===
                    "https://isabellaentire.com/zjxw5d0c?key=937c0545bfc6a8e5700000821b186ab6"
                      ? "bg-blue-700 text-white"
                      : "bg-blue-500 text-white"
                  }`}
                >
                  FULL HD
                </a>
                {match.urlStreams && match.urlStreams.length > 0 ? (
                  match.urlStreams.map((source, index) => (
                    <button
                      key={index}
                      onClick={() => handleSourceClick(source)}
                      className={`px-4 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 ${
                        activeSource === source
                          ? "bg-blue-700 text-white"
                          : "bg-blue-500 text-white"
                      }`}
                    >
                      Source {index + 1}
                    </button>
                  ))
                ) : (
                  <p>No additional sources available</p>
                )}
              </div>
            </div>

            <div className="bg-gray-100 p-4 rounded-lg mb-4">
              <h3 className="text-lg font-semibold mb-2">Join Our Telegram:</h3>
              <a
                href="https://t.me/nobaryukbro"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center bg-gray-500 text-white px-4 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
              >
                <FaTelegramPlane className="mr-2" />
                LET'S JOIN
              </a>
            </div>
          </div>

          <div className="lg:col-span-1">
            <h3 className="text-lg font-semibold mb-2 text-black">
              Ongoing matches as well:
            </h3>
            <div className="space-y-4">
              {ongoingMatches.length > 0 ? (
                ongoingMatches.map((ongoingMatch, index) => (
                  <Card
                    key={index}
                    competition={ongoingMatch.competition}
                    date={ongoingMatch.date}
                    time={ongoingMatch.time}
                    homeName={ongoingMatch.homeName}
                    homeLogo={ongoingMatch.homeLogo}
                    awayName={ongoingMatch.awayName}
                    awayLogo={ongoingMatch.awayLogo}
                    onClick={() => handleCardClick(ongoingMatch)}
                    tab="Football" // Pastikan tab diisi dengan tab yang sesuai
                  />
                ))
              ) : (
                <p className="text-center text-gray-500">No matches found</p>
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Live;
